/* eslint-disable prettier/prettier */
/* eslint-disable no-await-in-loop */
// ./src/components/forms/FormVehicleNew/index.tsx
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';

import { FormHandles } from '@unform/core';
import { IoClose } from 'react-icons/io5';
import SwiperCore, {
  A11y,
  Autoplay,
  Lazy,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper';
import { SwiperSlide, Swiper } from 'swiper/react';
import * as Yup from 'yup';

import {
  SubtitleSecondary,
  Paragraph,
  InputSelect,
  InputText,
  InputMask,
  ButtonMain,
  ButtonDefault,
  ButtonOutline,
} from '@components/index';

// Importações internas
import ProductItem from '@components/modals/ProductModal';
import { ConfigLabel, ConfigStyles, ConfigValues } from '@config/index';
import Product from '@models/Product';
import Vehicle from '@models/Vehicle';
// import ProductItem from '@pages/public/Plan/ProductItem';
import { apiRebox, newContractStorageService } from '@services/index';
import { getValidationErrors } from '@utils/errors';
import { hotToast, toastify } from '@utils/notifiers';

import { schemaVehicle } from './schemaValidation';
import { ISelect, IFormVehicle, ICart } from './typing';

import {
  Container,
  FormVehicle,
  ProductModal,
  Sections,
  SectionsGroup,
  SectionsItem,
  SectionsPlansOptions,
  SectionsPlansOptionsGroup,
  SectionsPlansOptionsItem,
  SectionsPlansOptionsItemGroup,
  SliderContainer,
} from './styles';

interface IProps {
  forNewSale?: {
    advanceStep(): void;
  };
  setProduct: (e: any) => void;
  handleCalculatePrice: (e: any) => void;
  setFormVehicle?: (e: any) => void;
}

SwiperCore.use([A11y, Pagination, Navigation, Scrollbar, Autoplay, Lazy]);

const SWIPER_LIMIT_DELAY_IN_MM = 10000;

const FormVehicleNew: React.FC<IProps> = ({
  forNewSale,
  setProduct,
  handleCalculatePrice,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [isTrackerPlan, setIsTrackerPlan] = useState<boolean>(true);
  const [modelFormVehicle, setModelFormVehicle] = useState<any[]>([]);
  const [brandFormVehicle, setBrandFormVehicle] = useState<any[]>([]);
  const [formVehicle, setFormVehicle] = useState<IFormVehicle[]>([
    {
      vehicle_id: 1,
      brand: '',
      year: '',
      license_plate: '',
      model: '',
      color: '',
    },
  ]);
  const [vehicle, setVehicle] = useState<Vehicle[]>([]);
  const [brands, setBrands] = useState<ISelect[]>([]);
  const [brandsTracker, setBrandsTracker] = useState<ISelect[]>([]);
  const [models, setModels] = useState<ISelect[]>([]);
  const [modelsTracker, setModelsTracker] = useState<ISelect[]>([]);
  const [isOpenProductModal, setIsOpenProductModal] = useState(false);
  const [showPlansByVehicleSize, setShowPlansByVehicleSize] = useState<number>(
    1,
  );
  const [showPlansByPlanType, setShowPlansByPlanType] = useState<number>(1);
  const [products, setProducts] = useState<Product[]>([]);

  const classification = useMemo(() => {
    const { moto_tricycle } = ConfigValues.rebox.product.classification;
    const productStorage = newContractStorageService.getProduct();
    const savedClassification = localStorage.getItem(
      'classification_vehicle_type',
    );
    let tempValue = '';
    for (let index = 0; index < productStorage.length; index++) {
      if (productStorage[index].query === '' && savedClassification) {
        productStorage[index].query = JSON.parse(
          savedClassification,
        ).toUpperCase();
      }
      if (productStorage[index].query === moto_tricycle) {
        tempValue = 'MOTO';
      } else {
        tempValue = productStorage[index].query;
      }
    }
    return tempValue;
  }, []);

  // const getVehicleById = useCallback(async (id: string) => {
  //   try {
  //     const { data: responseVehicle } = await apiRebox.get(
  //       `/users/vehicles/${id}`,
  //     );
  //     const vehicleFound: Vehicle = responseVehicle.data;

  //     setVehicle([...vehicle, vehicleFound]);
  //     // getModels(vehicleFound.brand.toUpperCase());
  //     setFormVehicle([
  //       {
  //         vehicle_id: 1,
  //         brand: vehicleFound.brand,
  //         year: vehicleFound.year,
  //         license_plate: vehicleFound.license_plate,
  //         model: vehicleFound.model,
  //         color: vehicleFound.color,
  //       },
  //     ]);
  //     formRef.current?.setData({ license_plate: vehicleFound.license_plate });
  //   } catch (error) {
  //     toastify(
  //       'Ops! Houve um erro ao tentar buscar os dados do veículo.',
  //       'error',
  //     );
  //   }
  // }, []);

  // const getClassifications = useCallback(async () => {
  //   try {
  //     const { data: responseCarClassifications } = await apiRebox.get(`/cars`);
  //     setClassifications(responseCarClassifications.data);
  //   } catch (error) {
  //     toastify(
  //       'Ops, não conseguimos buscar as classificações de veículos existentes.',
  //       'error',
  //     );
  //   }
  // }, []);

  const getBrands = useCallback(async (value: string, index: number) => {
    try {
      const { data: responseCarBrands } = await apiRebox.get(`/cars`, {
        params: {
          classification: value,
          onlyBrand: true,
          per_page: 500,
        },
      });

      const brandsForTracker = responseCarBrands.data.map((brand: any) => ({
        label: brand.brand,
        value: brand.brand,
        for_tracker: brand.is_for_tracker_plan,
      }));

      const brandsAssistance = brandsForTracker.filter(
        (brand: any) => brand.for_tracker === false,
      );

      setBrands(brandsAssistance);
      setBrandsTracker(brandsForTracker);

      newContractStorageService.updateBrandFormVehicle(brandsForTracker);
    } catch (error) {
      toastify(
        'Ops, não conseguimos buscar as marcas de veículos existentes.',
        'error',
      );
    }
  }, []);

  const getModels = async (value: string, index: number) => {
    try {
      const cart = newContractStorageService.getCart();
      let savedClassification = localStorage.getItem(
        'classification_vehicle_type',
      );
      if (savedClassification) {
        savedClassification = JSON.parse(savedClassification);
      }
      const { data: responseCarModels } = await apiRebox.get(`/cars?`, {
        params: {
          brand: value,
          classification:
            value === 'MOTO'
              ? 'MOTO'
              : cart[index].classification
                ? cart[index].classification
                : savedClassification?.toUpperCase(),
          onlyModel: true,
          per_page: 500,
        },
      });

      const modelsForTracker = responseCarModels.data.map((model: any) => ({
        label: model.model,
        value: model.model,
        for_tracker: model.is_for_tracker_plan,
      }));

      const modelsAssistance = modelsForTracker.filter(
        (brand: any) => brand.for_tracker === false,
      );

      setModels(modelsAssistance);
      setModelsTracker(modelsForTracker);
      // setModelFormVehicle(prevState => {
      //   const handler = prevState;
      //   handler[index] = filtered_models;
      //   return handler;
      // });
    } catch (error) {
      toastify(
        'Ops, não conseguimos buscar as modelos de veículos existentes.',
        'error',
      );
    }
  };

  const handleRegisterVehicle = useCallback(async () => {
    const idHotToast = hotToast(`Aguarde...`, 'loading');
    try {
      setLoading(prevState => !prevState);

      for await (const vehicleForm of formVehicle) {
        await schemaVehicle.validate(vehicleForm, {
          abortEarly: false,
        });
      }

      const vehicles = formVehicle.map(form => ({
        users_id: newContractStorageService.getCustomer().id,
        classification,
        brand: form.brand,
        model: form.model,
        license_plate: form.license_plate,
        year: form.year,
        color: form.color,
        status: ConfigValues.rebox.vehicle.status.active,
        armored: false,
      }));

      const vehicleSaved = newContractStorageService.getVehicle();

      let responseVehicle = null;

      if (vehicleSaved[vehicleSaved.length - 1]) {
        for await (const [index, vehicleBody] of vehicles.entries()) {
          responseVehicle = apiRebox.put(
            `/users/vehicles/${vehicleSaved[index].id}`,
            vehicleBody,
          );
        }
      } else {
        // responseVehicle = await apiRebox.post(`/users/vehicles`, vehicles);
        responseVehicle = await apiRebox.post(`/users/vehicles`, vehicles);
      }

      let vehiclesCreated = null;

      if (responseVehicle) {
        const { data } = await responseVehicle;
        const { data: vehicle_created } = data;
        vehiclesCreated = vehicle_created;
      }

      // toastify(header.message, 'success');

      // Caso o cliente esteja sendo criado no momento da venda
      if (forNewSale) {
        // If/else temporário até adaptar a api para o update retornar um array
        if (vehiclesCreated.length) {
          for (const oneVehicle of vehiclesCreated) {
            newContractStorageService.updateVehicle({
              id: oneVehicle.id,
              field_type: 'VEHICLE_LICENSE_PLATE',
              query: oneVehicle.license_plate,
            });
            forNewSale.advanceStep();
          }
        } else {
          newContractStorageService.updateVehicle({
            id: vehiclesCreated.id,
            field_type: 'VEHICLE_LICENSE_PLATE',
            query: vehiclesCreated.license_plate,
          });
          forNewSale.advanceStep();
        }
      }

      newContractStorageService.updateFormVehicle(formVehicle);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        const { brand, model, license_plate, year, color } = errors;

        if (brand) toastify(brand, 'error');
        if (model) toastify(model, 'error');
        if (license_plate) toastify(license_plate, 'error');
        if (year) toastify(year, 'error');
        if (color) toastify(color, 'error');
      } else if (error.response) toastify(error.response.data.error, 'error');
    } finally {
      setLoading(prevState => !prevState);
      hotToast(idHotToast, 'dismiss');
    }
  }, [classification, formVehicle]);

  const handleOpenModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setIsOpenProductModal(prev => !prev);
  };

  const handleFormVehicleValues = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
  ) => {
    if (event.target.name === 'brand') {
      // await getBrands(event.target.value, index);
      await getModels(event.target.value, index);
      newContractStorageService.updateModelFormVehicle(modelFormVehicle);
    }
    setFormVehicle(currentForm =>
      currentForm.map((currForm, formIndex) =>
        formIndex === index
          ? {
            ...currForm,
            [event.target.name]: event.target.value,
          }
          : currForm,
      ),
    );
  };

  const getProducts = useCallback(async () => {
    try {
      // setLoading(true);

      const response = await apiRebox.get(
        `/products?details=all&per_page=1000&type=${ConfigValues.rebox.product.type.plan}&category=${ConfigValues.rebox.product.category.vehicle_assistance}`,
      );

      const { header, data } = response.data;

      setProducts(data);
    } catch (error) {
      toastify('Houve um erro no carregamento da página.', 'error');
    }
  }, []);

  useEffect(() => {
    getProducts();
    const storageProducts = newContractStorageService.getCart();
    for (let index = 0; index < storageProducts.length; index++) {
      getBrands(classification, index);
    }
    const storageBrandFormVehicle = newContractStorageService.getBrandFormVehicle();
    const storageFormVehicle = newContractStorageService.getFormVehicle();
    const storageModelFormVehicle = newContractStorageService.getModelFormVehicle();
    if (storageModelFormVehicle.length !== 0) {
      setBrandFormVehicle(storageBrandFormVehicle);
      setFormVehicle(storageFormVehicle);
      setModelFormVehicle(storageModelFormVehicle);
    }
  }, []);

  useEffect(() => {
    newContractStorageService.updateFormVehicle(formVehicle);
  }, [formVehicle]);

  useEffect(() => {
    if (isTrackerPlan === true) {
      setShowPlansByVehicleSize(2);
    } else {
      setShowPlansByVehicleSize(1);
    }
  }, [isTrackerPlan]);

  return (
    <Container>
      <FormVehicle
        ref={formRef}
        onSubmit={() => []}
        initialData={{
          year: vehicle[vehicle.length - 1]
            ? vehicle[vehicle.length - 1].year
            : '',
        }}
      >
        {newContractStorageService.getCart().map((product, index) => (
          <Sections key={formVehicle[index]?.vehicle_id}>
            <SubtitleSecondary
              textAlign="start"
              nameColor="black"
              fontSize={16}
            >
              {`Veículo ${index + 1}`}
            </SubtitleSecondary>
            <SectionsGroup>
              <SectionsItem>
                <SubtitleSecondary
                  textAlign="start"
                  nameColor="black"
                  fontSize={14}
                  style={{ marginBottom: '1vh' }}
                >
                  Marca
                </SubtitleSecondary>
                <InputSelect
                  name="brand"
                  options={
                    product?.name.toLowerCase().includes('tracker')
                      ? brandsTracker
                      : brands
                  }
                  // options={brands}
                  // selectedDefault={
                  //   formVehicle[index]
                  //     ? formVehicle[index].brand.toUpperCase()
                  //     : ''
                  // }
                  onChange={event => {
                    handleFormVehicleValues(event, index);
                  }}
                  placeholder="Selecione a marca"
                />
              </SectionsItem>

              <SectionsItem>
                <SubtitleSecondary
                  textAlign="start"
                  nameColor="black"
                  fontSize={14}
                  style={{ marginBottom: '1vh' }}
                >
                  Modelo
                </SubtitleSecondary>
                <InputSelect
                  name="model"
                  options={
                    product?.name.toLowerCase().includes('tracker')
                      ? modelsTracker
                      : models
                  }
                  // selectedDefault={
                  //   formVehicle[index]
                  //     ? formVehicle[index].model.toUpperCase()
                  //     : ''
                  // }
                  onChange={event => {
                    handleFormVehicleValues(event, index);
                  }}
                  placeholder="Selecione o modelo"
                  disabled={models.length === 0}
                // style={{
                //   cursor:
                //     modelFormVehicle.length === 0 ? 'not-allowed' : 'pointer',
                //   opacity: modelFormVehicle.length === 0 ? 0.5 : 1,
                // }}
                />
              </SectionsItem>
            </SectionsGroup>

            <SectionsGroup>
              <SectionsItem>
                <SubtitleSecondary
                  textAlign="start"
                  nameColor="black"
                  fontSize={14}
                  style={{ marginBottom: '1vh' }}
                >
                  Placa
                </SubtitleSecondary>
                <InputMask
                  name="license_plate"
                  mask="aaa-9*99"
                  value={
                    formVehicle[index]
                      ? formVehicle[index].license_plate.toUpperCase()
                      : ''
                  }
                  placeholder="Digite a placa"
                  onChange={event => {
                    handleFormVehicleValues(event, index);
                  }}
                  style={{ textTransform: 'uppercase' }}
                />
              </SectionsItem>

              <SectionsItem>
                <SubtitleSecondary
                  textAlign="start"
                  nameColor="black"
                  fontSize={14}
                  style={{ marginBottom: '1vh' }}
                >
                  Cor
                </SubtitleSecondary>
                <InputSelect
                  name="color"
                  options={ConfigLabel.rebox.others.vehicle.color}
                  selectedDefault={
                    formVehicle[index]
                      ? formVehicle[index].color.toUpperCase()
                      : ''
                  }
                  onChange={event => {
                    handleFormVehicleValues(event, index);
                  }}
                  placeholder="Selecione a cor"
                />
              </SectionsItem>
            </SectionsGroup>

            <SectionsGroup>
              <SectionsItem>
                <SubtitleSecondary
                  textAlign="start"
                  nameColor="black"
                  fontSize={14}
                  style={{ marginBottom: '1vh' }}
                >
                  Ano
                </SubtitleSecondary>
                <InputText
                  name="year"
                  type="number"
                  placeholder="Digite o ano"
                  min={0}
                  // value={
                  //   formVehicle[index] ? Number(formVehicle[index].year) : ''
                  // }
                  onChange={event => {
                    handleFormVehicleValues(event, index);
                    handleCalculatePrice(event);
                  }}
                />
              </SectionsItem>
            </SectionsGroup>
          </Sections>
        ))}

        <Sections>
          {/* <SectionsGroup>
            <SectionsItem>
              <SubtitleSecondary
                textAlign="start"
                nameColor="black"
                fontSize={14}
                style={{ marginBottom: '1vh' }}
              >
                Adicione um novo plano
              </SubtitleSecondary>
              <Paragraph
                textAlign="start"
                nameColor="black"
                opacity={0.8}
                fontWeight={500}
              >
                Você deseja contratar mais de um plano para outro veículo?
              </Paragraph>
              <ButtonOutline
                style={{
                  marginTop: '1rem',
                  maxWidth: '200px',
                }}
                onClick={handleOpenModal}
              >
                Adicionar
              </ButtonOutline>
            </SectionsItem>
          </SectionsGroup> */}

          <ButtonMain
            type="submit"
            loading={loading}
            style={{ marginTop: '4vh', maxWidth: 250 }}
            onClick={event => {
              event.preventDefault();
              handleRegisterVehicle();
            }}
          >
            {forNewSale ? 'Continuar' : 'Cadastrar'}
          </ButtonMain>
        </Sections>
      </FormVehicle>
      <ProductModal
        isOpen={isOpenProductModal}
        ariaHideApp={false}
        onRequestClose={() => setIsOpenProductModal(prev => !prev)}
        contentLabel="ProductsModal"
      >
        <ButtonDefault
          iconLeft={IoClose}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            maxWidth: 50,
            padding: 0,
          }}
          onClick={() => setIsOpenProductModal(prev => !prev)}
        />
        <SectionsPlansOptions>
          <SectionsPlansOptionsGroup>
            <SectionsPlansOptionsItem
              isButtonActive={showPlansByPlanType === 1}
              onClick={() => {
                setShowPlansByPlanType(1);
                setIsTrackerPlan(true);
              }}
            >
              <Paragraph
                nameColor={showPlansByPlanType === 1 ? 'blue' : 'black'}
              >
                Rastreador e Assistência 24h
              </Paragraph>
            </SectionsPlansOptionsItem>
            <SectionsPlansOptionsItem
              isButtonActive={showPlansByPlanType === 2}
              onClick={() => {
                setShowPlansByPlanType(2);
                setIsTrackerPlan(false);
              }}
            >
              <Paragraph
                nameColor={showPlansByPlanType === 2 ? 'blue' : 'black'}
              >
                Assistência 24h
              </Paragraph>
            </SectionsPlansOptionsItem>
          </SectionsPlansOptionsGroup>
        </SectionsPlansOptions>
        <SectionsPlansOptions>
          {isTrackerPlan ? (
            <>
              <SectionsPlansOptionsGroup>
                <SectionsPlansOptionsItem
                  isButtonActive={showPlansByVehicleSize === 2}
                  onClick={() => setShowPlansByVehicleSize(2)}
                >
                  <SectionsPlansOptionsItemGroup>
                    <Paragraph
                      nameColor={
                        showPlansByVehicleSize === 2 ? 'blue' : 'black'
                      }
                      fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
                      fontWeight={500}
                    >
                      Carros leves
                    </Paragraph>
                  </SectionsPlansOptionsItemGroup>
                </SectionsPlansOptionsItem>
                <SectionsPlansOptionsItem
                  isButtonActive={showPlansByVehicleSize === 3}
                  onClick={() => setShowPlansByVehicleSize(3)}
                >
                  <SectionsPlansOptionsItemGroup>
                    <Paragraph
                      nameColor={
                        showPlansByVehicleSize === 3 ? 'blue' : 'black'
                      }
                      fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
                      fontWeight={500}
                    >
                      Picapes / Suv
                    </Paragraph>
                  </SectionsPlansOptionsItemGroup>
                </SectionsPlansOptionsItem>
              </SectionsPlansOptionsGroup>
            </>
          ) : (
            <>
              <SectionsPlansOptionsGroup>
                <SectionsPlansOptionsItem
                  isButtonActive={showPlansByVehicleSize === 1}
                  onClick={() => setShowPlansByVehicleSize(1)}
                >
                  <SectionsPlansOptionsItemGroup>
                    <Paragraph
                      nameColor={
                        showPlansByVehicleSize === 1 ? 'blue' : 'black'
                      }
                      fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
                      fontWeight={500}
                    >
                      Motos / Triciclos
                    </Paragraph>
                  </SectionsPlansOptionsItemGroup>
                </SectionsPlansOptionsItem>
                <SectionsPlansOptionsItem
                  isButtonActive={showPlansByVehicleSize === 2}
                  onClick={() => setShowPlansByVehicleSize(2)}
                >
                  <SectionsPlansOptionsItemGroup>
                    <Paragraph
                      nameColor={
                        showPlansByVehicleSize === 2 ? 'blue' : 'black'
                      }
                      fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
                      fontWeight={500}
                    >
                      Carros leves
                    </Paragraph>
                  </SectionsPlansOptionsItemGroup>
                </SectionsPlansOptionsItem>
                <SectionsPlansOptionsItem
                  isButtonActive={showPlansByVehicleSize === 3}
                  onClick={() => setShowPlansByVehicleSize(3)}
                >
                  <SectionsPlansOptionsItemGroup>
                    <Paragraph
                      nameColor={
                        showPlansByVehicleSize === 3 ? 'blue' : 'black'
                      }
                      fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
                      fontWeight={500}
                    >
                      Picapes / Suv
                    </Paragraph>
                  </SectionsPlansOptionsItemGroup>
                </SectionsPlansOptionsItem>
              </SectionsPlansOptionsGroup>
            </>
          )}
        </SectionsPlansOptions>
        <SliderContainer>
          <Swiper
            allowSlideNext={true}
            allowSlidePrev={true}
            draggable={true}
            navigation={true}
            lazy={true}
            autoplay={{
              delay: SWIPER_LIMIT_DELAY_IN_MM,
              waitForTransition: false,
              reverseDirection: false,
              stopOnLastSlide: false,
              disableOnInteraction: true,
            }}
            breakpoints={{
              540: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1440: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
            }}
            style={{ display: false ? 'none' : 'flex' }}
          >
            {isTrackerPlan ? (
              <>
                {showPlansByVehicleSize === 2 &&
                  products.map(
                    product =>
                      product.status ===
                      ConfigValues.rebox.product.status.active &&
                      product.classification ===
                      ConfigValues.rebox.product.classification
                        .passenger_car &&
                      product.name.includes('tracker') && (
                        <SwiperSlide
                          key={product.id}
                          style={{ paddingTop: '2vh', margin: '0 auto' }}
                        >
                          <ProductItem
                            product={product}
                            setProduct={setProduct}
                            handleClose={setIsOpenProductModal}
                            setFormVehicle={setFormVehicle}
                          />
                        </SwiperSlide>
                      ),
                  )}

                {showPlansByVehicleSize === 3 &&
                  products.map(
                    product =>
                      product.status ===
                      ConfigValues.rebox.product.status.active &&
                      product.classification ===
                      ConfigValues.rebox.product.classification.pickup_suv &&
                      product.name.includes('tracker') && (
                        <SwiperSlide
                          key={product.id}
                          style={{ paddingTop: '2vh', margin: '0 auto' }}
                        >
                          <ProductItem
                            product={product}
                            setProduct={setProduct}
                            handleClose={setIsOpenProductModal}
                            setFormVehicle={setFormVehicle}
                          />
                        </SwiperSlide>
                      ),
                  )}
              </>
            ) : (
              <>
                {showPlansByVehicleSize === 1 &&
                  products.map(
                    product =>
                      product.status ===
                      ConfigValues.rebox.product.status.active &&
                      product.classification ===
                      ConfigValues.rebox.product.classification
                        .moto_tricycle && (
                        <SwiperSlide
                          key={product.id}
                          style={{ paddingTop: '2vh', margin: '0 auto' }}
                        >
                          <ProductItem
                            product={product}
                            handleClose={setIsOpenProductModal}
                            setProduct={setProduct}
                            setFormVehicle={setFormVehicle}
                          />
                        </SwiperSlide>
                      ),
                  )}

                {showPlansByVehicleSize === 2 &&
                  products.map(
                    product =>
                      product.status ===
                      ConfigValues.rebox.product.status.active &&
                      product.classification ===
                      ConfigValues.rebox.product.classification
                        .passenger_car &&
                      !product.name.includes('tracker') && (
                        <SwiperSlide
                          key={product.id}
                          style={{ paddingTop: '2vh', margin: '0 auto' }}
                        >
                          <ProductItem
                            product={product}
                            setProduct={setProduct}
                            handleClose={setIsOpenProductModal}
                            setFormVehicle={setFormVehicle}
                          />
                        </SwiperSlide>
                      ),
                  )}

                {showPlansByVehicleSize === 3 &&
                  products.map(
                    product =>
                      product.status ===
                      ConfigValues.rebox.product.status.active &&
                      product.classification ===
                      ConfigValues.rebox.product.classification.pickup_suv &&
                      !product.name.includes('tracker') && (
                        <SwiperSlide
                          key={product.id}
                          style={{ paddingTop: '2vh', margin: '0 auto' }}
                        >
                          <ProductItem
                            product={product}
                            setProduct={setProduct}
                            handleClose={setIsOpenProductModal}
                            setFormVehicle={setFormVehicle}
                          />
                        </SwiperSlide>
                      ),
                  )}
              </>
            )}
          </Swiper>
        </SliderContainer>
      </ProductModal>
    </Container>
  );
};

export default FormVehicleNew;
